import type { V2_MetaFunction } from "@remix-run/node";
import {
Links,
LiveReload,
Meta,
Outlet,
Scripts,
ScrollRestoration,
useLoaderData,
useLocation } from
"@remix-run/react";
import { metaV1 } from "@remix-run/v1-meta";

import axios from "axios";
import { stringify } from "qs";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import styles from "./styles/styles.css";

axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = { serialize: stringify };

export function links() {
  return [
  { rel: "stylesheet", href: styles },
  { rel: "icon", href: "/media/icons/favicon.svg" },
  { rel: "icon", href: "/media/icons/favicon.png", sizes: "any" },
  { rel: "icon", href: "/media/icons/favicon.ico", sizes: "any" }];

}

export const meta: V2_MetaFunction = (args) => {
  return metaV1(args, {
    charset: "utf-8",
    viewport: "width=device-width,initial-scale=1",
    title: "The Underground",
    description: "Expressing Culture!",

    "og:title": "The Underground",
    "og:description": "Expressing Culture!",
    "og:type": "website",
    "og:url": "https://theunderground.ke",
    "og:image": "https://theunderground.ke/media/images/cover.png",
    "og:image:alt": "The Underground",
    "og:image:width": "3001",
    "og:image:height": "1651",
    "og:locale": "en_GB",

    "twitter:card": "summary_large_image",
    "twitter:domain": "theunderground.ke",
    "twitter:creator": "@theundergrndke",
    "twitter:description": "Expressing Culture!",
    "twitter:url": "https://theunderground.ke",
    "twitter:image": "https://theunderground.ke/media/images/cover.png"
  });
};

export { loader } from "./root.server";

export const unstable_shouldReload = () => false;

export default function App() {
  const location = useLocation();
  const loaderData = useLoaderData();
  const { gaTrackingId } = loaderData;

  useEffect(() => {
    if (gaTrackingId?.length) {
      if (typeof window == "undefined") {
        console.log("Google Analytics PageView");
        console.log({ location, page: location.pathname });
      }
      ReactGA.ga("send", "pageview", {
        page: location.pathname
      });
      // ReactGA.pageview(location.pathname);
    }
  }, [location, gaTrackingId]);
  return (
    <html className="" lang="en">
			<head>
				<Meta />
				<Links />
			</head>
			<body className="preload">
				<script
        // async
        id="preload"
        dangerouslySetInnerHTML={{
          __html: `
								window.addEventListener("load",function(){
									document.body.classList.remove("preload")
								});
									`
        }} />

				{!gaTrackingId ? null :
        <>
						<script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />

						<script
          async
          id="gtag-init"
          dangerouslySetInnerHTML={{
            __html: `
										window.dataLayer = window.dataLayer || [];
										function gtag(){dataLayer.push(arguments);}
										gtag('js', new Date());
										gtag('config', '${gaTrackingId}', {
										page_path: window.location.pathname,
										});
									`
          }} />

					</>}

				<Outlet context={loaderData} />
				<ScrollRestoration />
				<Scripts />
				<LiveReload />
			</body>
		</html>);

}